<template>
   <div id="main-wrapper">
      <!-- <Container/> -->
      <section class="blockElement" id="contactForm">
         <div class="container">
            <div class="text-center">
               <h1 class="mb-0">{{contentlist.contact_content1}}</h1>
               <p class="d-block mt-2 mt-md-4 mb-0">{{contentlist.contact_content2}}<br>
                  {{contentlist.contact_content3}}
               </p>
            </div>
         </div>
      </section>
      <section class="blockElement supportSection">
         <div class="container">
            <div class="row d-flex align-items-center">
               <div class="col-md-6 position-relative">
                  <img src="/assets/images/support-img.webp" alt="help" title="help" class="img-fluid supportImg" />
                  <p class="mb-0 semibold highlightText">{{contentlist.contact_content4}}</p>
               </div>
               <div class="col-md-6 mt-4 mt-md-0 mb-3 mb-md-0">
                  <div class="position-relative supportForm">
                     <div class="round-md shadow-md p-4 p-lg-5 bg-white position-relative">
                        <p :class="(msgType == 'success')?'alert alert-success green':'alert alert-danger red'" v-if="errorMsg != ''">{{errorMsg}}</p>
                        <!-- <p v-if="pageLoader">loading..</p> -->
                        <div class="row">
                           <div class="col-md-6 mb-4">
                              <input type="text"  v-model="fname" placeholder="First Name" class="form-control" />
                               <p class="text-danger" v-show="submitted && fname == ''">The fname field is required</p>
                           </div>
                           <div class="col-md-6 mb-3">
                              <input type="text" v-model="lname" placeholder="Last Name" class="form-control" />
                               <p class="text-danger" v-show="submitted && lname == ''">The lname field is required</p>
                           </div>
                           <div class="col-md-12 mb-4">
                              <input type="email"  v-model="email" placeholder="Work Email Address" class="form-control" />
                               <p class="text-danger" v-show="submitted && email == ''">The email field is required</p>
                               <p class="text-danger" v-show="submitted && email_msz != ''">{{email_msz}}</p>
                           </div>
                           <div class="col-md-12 mb-4">
                              <input type="text"   v-model="subject" placeholder="Subject" class="form-control" />
                               <p class="text-danger" v-show="submitted && subject == ''">The subject field is required</p>
                           </div>
                           <label class="form-label">{{contentlist.contact_content11}}</label>
                           <div class="col-md-12 mb-4">
                              <textarea placeholder="Hello Capital Wallet, I need your help." v-model="message" class="form-control"></textarea>
                               <p class="text-danger" v-show="submitted && message == ''">The message field is required</p>
                           </div>
                           <div class="text-center mt-3 d-block" v-if="!pageLoader"><a href="javascript:void(0);" v-on:click.prevent="submit()" class="themeBtn medium">{{contentlist.contact_content12}}</a></div>
                        </div>
                        <div class="text-center" v-if="pageLoader == true">
                           <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                              <span class="visually-hidden">Loading...</span>
                           </div>
                           <!-- <p class="mb-0 semibold pt-3">Loading</p> -->
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="blockElement lightgreybg">
         <div class="container">
            <h2 class="mb-4 mb-md-5 text-center text-md-start">{{contentlist.contact_content5}}</h2>
            <div class="d-block d-md-flex justify-content-between">
               <div class="d-flex align-items-center shadow-md rounded-pill bg-white py-3 py-lg-4 supportBox">
                  <img src="/assets/images/general-inqury.webp" alt="General queries" title="General queries" class="img-fluid">
                  <div class="ms-3 ms-lg-4">
                     <p class="mb-0">{{contentlist.contact_content6}}</p>
                     <a href="mailto:contact@capitalwallet.com">
                        <h6 class="mb-0">{{contentlist.contact_email_content}}</h6>
                     </a>
                     
                  </div>
               </div>
               <div class="d-flex align-items-center shadow-md rounded-pill bg-white py-3 py-lg-4 supportBox">
                  <img src="/assets/images/technical-support.webp" alt="General queries" title="General queries" class="img-fluid">
                  <div class="ms-3 ms-lg-4">
                     <p class="mb-0">{{contentlist.contact_content7}}</p>
                     <a href="mailto:support@capitalwallet.com">
                        <h6 class="mb-0">{{contentlist.support_email_content}}</h6>
                     </a>
                  </div>
               </div>
            </div>
            <div class="d-block d-md-flex justify-content-between">
               <div class="d-flex align-items-center shadow-md rounded-pill bg-white py-3 py-lg-4 supportBox">
                  <img src="/assets/images/existing-client.webp" alt="General queries" title="General queries" class="img-fluid">
                  <div class="ms-3 ms-lg-4">
                     <p class="mb-0">{{contentlist.contact_content8}}</p>
                     <a href="mailto:support@capitalwallet.com">
                        <h6 class="mb-0">{{contentlist.support_email_content}}</h6>
                     </a>
                  </div>
               </div>
               <div class="d-flex align-items-center shadow-md rounded-pill bg-white py-3 py-lg-4 supportBox">
                  <img src="/assets/images/send-feedback.webp" alt="General queries" title="General queries" class="img-fluid">
                  <div class="ms-3 ms-lg-4">
                     <p class="mb-0">{{contentlist.contact_content9}}</p>
                     <a href="#contactForm">
                        <h6 class="mb-0">{{contentlist.contact_content10}}</h6>
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="blockElement" data-aos="fade-up" data-aos-duration="2000">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 text-center mb-3 mb-md-4">
                  <h2 class="borderBefore position-relative d-inline-flex">{{contentlist.questions_you_may_have_content}}</h2>
               </div>
               <div class="col-12 col-lg-8" v-if="Object.keys(faqslist).length > 0">
                  <div class="accordion" id="accordionExample" v-for="value,key in faqslist.dataArr['getting-started']" :key="key" :val="value" >
                     <div class="accordion-item" v-if="key <= 2">
                         
                        <h6 @click="accordianFunc(key)" >
                           <button  type="button" class="accordion-button" :class="(setKey == key) ? 'accordionButtonArrow' : ''">{{value.articleName}} </button>
                        </h6>
                        <div  :class="(setKey == key) ? 'accordianContainerShow' : 'accordianContainerHide'">
                           <div class="accordion-body pt-0">
                              <p class="mb-0" v-html="value.description"></p>
                           </div>
                        </div>
                     </div>
                     <!-- <div class="accordion-item">
                        <h6 class="accordion-header medium" id="headingOne">
                           <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">What are the benefits for my Company from accepting crypto-payments?</button>
                        </h6>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                           <div class="accordion-body pt-0">
                              <span class="primarycolor pb-3 d-block semibold">1. Lower fees</span>
                              <p class="mb-0">You can enjoy a leaner, more profitable business by integrating crypto payments.  Unlike traditional transactions, accepting crypto as a payment allows you and clients to avoid unnecessary operational costs incurred by traditional payment systems.</p>
                              <span class="primarycolor pb-3 pt-3 d-block semibold">2. Borderless</span>
                              <p class="mb-0">Cryptocurrencies are global and allow anyone to instantly send and receive crypto all over the world. With cross-border payment solutions, you can widen your customer base.</p>
                              <span class="primarycolor pb-3 pt-3 d-block semibold">3. No chargebacks</span>
                              <p class="mb-0">Crypto payments via Capital Wallet helps you prevent chargeback fraud, protecting you and ensuring that you are not put in a disadvantageous position when doing business.</p>
                              <span class="primarycolor pb-3 pt-3 d-block semibold">4. Easy integration to your own and CRM</span>
                              <p class="mb-0">We can help you create a seamless checkout experience on your own app/site using Capital Wallet payment processing tool.</p>
                              <span class="primarycolor pb-3 pt-3 d-block semibold">5. Forward-thinking brand</span>
                              <p class="mb-0">Αccepting crypto also allows you to future-proof your business in the form of payment experience.</p>
                              <span class="primarycolor pb-3 pt-3 d-block semibold">6. Superior customer experience</span>
                              <p class="mb-0">Providing an additional option for transactions offers your customers more flexibility in payment, ensuring that you don’t lose any potential sales with a narrow range of payment options.</p>
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h6 class="accordion-header medium" id="headingTwo">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">What services do I get with Capital Wallet?</button>
                        </h6>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                           <div class="accordion-body pt-0">
                              <p class="mb-0">We offer a robust crypto-payment solution that is suitable for any Business globally:</p>
                              <span class="primarycolor pb-3 pt-3 d-block semibold">1. Receive Payments in Cryptos:</span>
                              <p class="mb-0">You can receive payments from your clients in all major cryptos and stablecoin.</p>
                              <span class="primarycolor pb-3 pt-3 d-block semibold">2. Instantly Convert Cryptos int Cryptos/Stablecoins/Fiat*:</span>
                              <p class="mb-0">You may swap your cryptos manually to protect your balance from Market volatility or you may authorize us to automatically convert your deposits in any supported Crypto / stablecoin or Fiat* currency. The Instant conversion takes place in real-time, at the best exchange rates that we lock for you.</p>
                              <span class="f-16 primarycolor pt-2 d-block">*Conversions into Fiat currencies are subject to Due Diligence and compliance.</span>
                              <span class="primarycolor pb-3 pt-3 d-block semibold">3. Withdraw:</span>
                              <p class="mb-0">hassle-free procedure, you can authorize Capital Wallet to auto-settle your cryptos/stablecoins/fiat* at a specified time and day in a predefined wallet/ account.</p>
                              <span class="f-16 primarycolor pt-2 d-block">*Fiat withdrawals are supported but are subject to Due Diligence and compliance.</span>
                              <span class="primarycolor pb-3 pt-3 d-block semibold">4. Store Cryptos:</span>
                              <p class="mb-0">You may store as many cryptos as you want and feel safe as we apply offline cold-storage for all our clients.</p>
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h6 class="accordion-header medium" id="headingThree">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Is it easy to sign up in Capital Wallet?</button>
                        </h6>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                           <div class="accordion-body pt-0">
                              <p class="mb-0">Yes!
                                 <br>
                                 Click on Register, insert your company name, your company email address, create your password and your Capital wallet is created!
                                 <br>
                                 To activate your account, you need to submit the required documentation for the KYB Process. 
                              </p>
                           </div>
                        </div>
                     </div> -->
                  </div>
               </div>
            </div>
            <div class="d-flex justify-content-center mt-3 mt-md-5">
               <router-link :to="entityPath+'/help-centre'" class="themeBtn medium">{{contentlist.explore_more_content}}</router-link>
            </div>
         </div>
      </section>
      <!-- <Footer /> -->
      <section class="blockElement primarybg" data-aos="fade-up" data-aos-duration="1800">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 text-center primarybgSection">
                  <div class="criptoIcon">
                     <span class="left-tp one"><img src="/assets/images/onHover-2.webp" alt="Icon" title="Crypto 1" width="77" height="90" class="img-fluid"></span>
                     <span class="left-tp tow"><img src="/assets/images/onHover-1.webp" alt="Icon" title="Crypto 2" width="98" height="98" class="img-fluid"></span>
                     <span class="left-tp three"><img src="/assets/images/onHover-3.webp" alt="Icon" title="Crypto 3" width="56" height="89" class="img-fluid"></span>
                     <span class="left-tp four"><img src="/assets/images/onHover-4.webp" alt="Icon" title="Crypto 4" width="87" height="86" class="img-fluid"></span>
                  </div>
                  <div class="content position-relative">
                     <h2 class="whitecolor">{{contentlist.get_started_content}}</h2>
                        <p class="whitecolor regular mb-0">{{contentlist.cta_three_content2}}</p>
                        <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="whiteBtn medium mt-4">{{contentlist.register_now_content}}</a>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>
<script>
// import getstarted from "../../components/shared/get_started";
import commonAllmixins from "@/plugins/commonAll";
import AOS from "aos";
export default {
    name: 'contact_us',
    mixins:[commonAllmixins],
    data() {
        return {
           fname:'',
           lname:'',
           email:'',
           subject:'',
           message:'',
           errorMsg: "", 
           msgType: "",
           submitted : false,
           pageLoader : false,
           email_msz : '',
           setKey : 0
        }
    },  
    components: { 
       // getStarted : getstarted,
        
    },
    methods: {
          accordianFunc(getKey){
          if(getKey == this.setKey){
            this.setKey = -1
         }else{
            this.setKey = getKey
         }
      },
        submit() {
            const _that = this;
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
             _that.email_msz = ''

            if (_that.fname == '' || _that.lname == '' || _that.email == '' || _that.subject == '' || _that.message == '') {
                _that.submitted = true; 
            }else if(!(_that.email.match(validRegex))){
               _that.submitted = true; 
              _that.email_msz = 'please enter valid email address'
            }else{
                 _that.submitted = false; 
                  _that.email_msz = ''
                //let text_data = "firstname :"+ _that.fname +", lastname : "+ _that.lname +", email :"+ _that.email +", phone_no :"+ _that.phone_no +", message :"+ _that.message  
                let input_json={
                    'globalParams' :{
                        // "text": text_data,
                        // "email": _that.email,
                        // "Fname": _that.fname,
                        // "Lname": _that.lname,
                        // "phone_no": _that.phone_no,
                        // "message": _that.message,
                        "name" : _that.fname +' '+ _that.lname,
                        "email" : _that.email,
                        "entity" : (_that.entityName == 'global') ? 'DMCC' : 'APAC',
                        "subject" : '',
                        "text" : "name"+ " = "+_that.fname +' '+ _that.lname +", "+ "email"+ " = "+_that.email +" ,"+ "subject"+ " = "+ _that.subject +", "+"msg"+ " = "+ _that.message
                    },
                    'localParams' :{ "parameter" : _that }
                }
                _that._runContactUs(input_json)
            }
        },
        reset(){
            this.fname = ''
            this.lname = ''
            this.email = ''
            this.subject = ''
            this.message = ''
            this.submitted = false
        }
    },
    mounted(){
        AOS.init();
    },
    created(){
      if(this.$route.query.page && this.$route.query.page == 'home'){
        this.subject = 'Demo Request'
        this.message = 'I would like to request a Demo of Capital wallet'
      }
    }  
}
</script>